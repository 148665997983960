import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {imageError} from 'src/app/utils/broken-image'

export function FallbackView() {
  return (
    <>
      <div
        className='flex justify-center h-full items-center'
        data-testid='fallback-view-component'
      >
        <div>
          <img
            onError={(e) => imageError(e)}
            className='mb-4'
            src={toAbsoluteUrl('/Loading.gif')}
            alt='Makan Ya !'
            style={{height: 80}}
          />
          <span className='ms-4'>Loading ...</span>
        </div>
      </div>
    </>
  )
}
